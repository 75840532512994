import { CommonContentLoader } from '../Common/content-loader/common-content-loader';
import { CommonLogger } from '../Common/logger/logger';
import {
	setSourcesInitializedTiming
} from '@CaseOne/Common/utilities/performance-check-utils/set-sources-downloaded-timing';

declare const HAS_INITIAL_LOADER: boolean;

setSourcesInitializedTiming(window);

if (window.AppData && window.AppData.LogEnabled) {
	window.initialLogger = new CommonLogger();
}

if (HAS_INITIAL_LOADER) {
	window.initialLoader = new CommonContentLoader({
		isOpaque: true,
	});
	window.initialLoader.enable();
}
