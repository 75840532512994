import { COMMON_LOGGER_LOG_LEVEL } from './logger-log-level.enum';

const COMMON_LOGGER_MAX_SAME_ERROR_COUNT = 3;

export interface ICommonLoggerHashchange {
	oldURL: string,
	newURL: string,
	timeStamp: number,
}

export interface ICommonLoggerLogError {

	// time markers
	time: {
		startUTCTime: string,  // start app time in UTC Date string
		errorUTCTime: string,  // error time in UTC Date string
		uptime: number,  // uptime in ms before error
	},

	// window.navigator data
	navigator: {
		connection?: {
			downlink: number,
			effectiveType: string,
			type: string,
		},
		language: string,
		platform: string,
		userAgent: string,
	},

	// window.location data
	location: {
		href: string,
	},

	// hashchange events history data
	hashchange: ICommonLoggerHashchange[],

	// ErrorEvent data
	errorEvent?: {
		isTrusted: boolean,
		message: string,
		timeStamp: number,
		returnValue: boolean,
		stack: string,
		type: string,
	},

	isLastSameError: boolean,  // limit same errors with COMMON_LOGGER_MAX_SAME_ERROR_COUNT
}

export interface ICommonLoggerLog {
	Level: COMMON_LOGGER_LOG_LEVEL,
	Message: string,
	Error: ICommonLoggerLogError,
}

export class CommonLogger {
	private initDate = new Date();
	private hashchange: ICommonLoggerHashchange[] = [];
	private previousErrors: Map<string, number> = new Map();

	constructor() {
		window.addEventListener('error', (e) => {
			this.logErrorEvent(e);
		});
		window.addEventListener('hashchange', (e) => {
			this.hashchange.push({
				oldURL: e.oldURL,
				newURL: e.newURL,
				timeStamp: e.timeStamp,
			});
		});
	}

	log (
		message: string,
		level: COMMON_LOGGER_LOG_LEVEL,
		e?: ErrorEvent,
	) {
		const currentDate = new Date();
		const uptime = currentDate.getTime() - this.initDate.getTime();

		const log: ICommonLoggerLog = {
			Level: level,
			Message: message,
			Error: {
				time: {
					startUTCTime: this.initDate.toUTCString(),
					errorUTCTime: currentDate.toUTCString(),
					uptime,
				},
				navigator: window.navigator ? {
					// @ts-ignore
					connection: window.navigator.connection ? {
						// @ts-ignore
						downlink: window.navigator.connection.downlink,
						// @ts-ignore
						effectiveType: window.navigator.connection.effectiveType,
						// @ts-ignore
						type: window.navigator.connection.type,
					} : null,
					language: window.navigator.language,
					platform: window.navigator.platform,
					userAgent: window.navigator.userAgent,
				} : null,
				location: {
					href: window.location.href,
				},
				hashchange: this.hashchange,
				errorEvent: e ? {
					isTrusted: e.isTrusted,
					message: e.message,
					timeStamp: e.timeStamp,
					returnValue: e.returnValue,
					stack: e.error ? e.error.stack : null,
					type: e.type,
				} : null,
				isLastSameError: false,
			},
		};

		this.send(log);
	}

	logErrorEvent (e: ErrorEvent) {
		this.log(e.message, COMMON_LOGGER_LOG_LEVEL.ERROR, e);
	}

	private send (log: ICommonLoggerLog) {
		const previousError = this.previousErrors.get(log.Message);
		const newCount = (previousError || 0) + 1;

		this.previousErrors.set(log.Message, newCount);

		if (newCount <= COMMON_LOGGER_MAX_SAME_ERROR_COUNT) {
			if (newCount === COMMON_LOGGER_MAX_SAME_ERROR_COUNT) {
				log.Error.isLastSameError = true;
			}

			const http = new XMLHttpRequest();  // for IE11

			http.open('POST', 'api/Logs/Add', true);
			http.setRequestHeader('Content-type', 'application/json');
			http.send(JSON.stringify(log));
		}
	}
}
